import { ElMessage } from 'element-plus'

export default defineNuxtRouteMiddleware((to, from) => {
  const hasToken = $cookies.get('apollo-token')
  if (!hasToken) {
    localStorage.setItem('temporaryRedirection', to.fullPath)
    return navigateTo('/login')
  }
})

function redirectToLogin(message: any) {
  logout()
  $cookies.remove('apollo-token');
  if (message) {
    ElMessage({
      showClose: true,
      message,
      type: 'error'
    })
  }
}

function notAuthorizedMessage() {
  switch (navigator.language) {
    case 'es-ES':
      return 'No estás autorizado a entrar'
    case 'en-EN':
      return 'You are not authorized to enter'
    case 'fr-FR':
      return 'Vous n\'estez pas autorisé d\'entrer'
    case 'de-DE':
      return 'Du bist nicht berechtigt, aufzurufen'
    case 'sv-SE':
      return 'Du är inte godkänt att in'
    case 'ar-AE':
      return 'ليس لديك الصلاحية للدخول'
    default:
      return 'You are not authorized to enter'
  }
}